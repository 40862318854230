export const vantUyghur = {
  'zh-CN': {
    save: 'saklax',
    delete: 'yuyuwetix',
    confirmDelete: 'rasla yuyiwetamsiz？',
    cancel: 'bikar kilix',
    confirm: 'mukumlaxturux',
    name: 'isim famila',
    tel: 'yanfon nomuri',
    nameEmpty: 'isim familigizni kirkuzug',
    telInvalid: 'togra telifun nomurigizni kirguzug',
    telEmpty: 'telifon nomurigizni kirguzug',
    vanAddressEdit: {
      area: 'rayon',
      areaEmpty: 'royonni tallag',
      addressEmpty: 'tapsiliy adresni toldurug',
      telPlaceholder: 'mal tapxurwalguqinig yanfon nomuri',
      namePlaceholder: 'mal tapxurwalguqinig ismi',
      areaPlaceholder: 'wilayat/xahar/rayon ni tallag'
    },
    vanAddressEditDetail: {
      label: 'tapsiliy adres',
      placeholder: 'koqa，kawat sani，ixik nomuri katarlik uqurlar'
    },
    vanAddressList: {
      add: 'yegi adresni kirguzux'
    }
  }
}
