/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2021-03-29 14:16:34
 * @LastEditors: yaozuo
 * @LastEditTime: 2021-11-04 15:07:57
 */

import { globalBeforeRouter, globalAfterRouter } from '@/common/router/base'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import ContributionList from './views/contribution-list/contribution-list.vue'

Vue.use(VueRouter)

export const routes: RouteConfig[] = [
  {
    name: 'onlineService',
    path: '/onlineService',
    component: () => import('./views/user/help-feedback/onlineService.vue'),
    meta: {
      title: '在线客服',
      auth: true,
      login: true
    }
  },
  {
    name: 'HelpFeedback',
    path: '/help-feedback',
    component: () => import('./views/user/help-feedback/help-feedback.vue'),
    meta: {
      title: '帮助与反馈',
      auth: true,
      login: true
    }
  },
  {
    name: 'HelpCommit',
    path: '/help-commit',
    component: () => import('./views/user/help-feedback/help-commit.vue'),
    meta: {
      title: '意见反馈',
      auth: true,
      login: true,
      report: true,
      pageId: 'page_h5_help_feedback',
      pageName: '意见反馈',
      pageType: 'liveType'
    }
  },
  {
    name: 'HelpDetail',
    path: '/help-detail',
    component: () => import('./views/user/help-feedback/help-detail.vue'),
    meta: {
      title: '问题详情'
    }
  },
  {
    name: 'HelpList',
    path: '/help-list',
    component: () => import('./views/user/help-feedback/help-list.vue'),
    meta: {
      title: '问题列表'
    }
  },

  {
    name: 'nullify',
    path: '/nullify',
    component: () => import('./views/user/nullify/index.vue'),
    meta: {
      title: '注销账号',
      auth: true,
      report: true,
      pageId: 'page_h5_Cancel_account',
      pageName: '注销账号',
      pageType: 'liveType'
    }
  },
  {
    name: 'creditsExchange',
    path: '/creditsExchange',
    component: () => import('./views/user/credits/creditsExchange.vue'),
    meta: {
      title: '兑换商城',
      auth: true,

      cache: true
    }
  },
  {
    name: 'gameRecord',
    path: '/gameRecord',
    component: () => import('./views/user/record/gameRecord.vue'),
    meta: {
      title: '积分记录',
      auth: true,
      report: true
    }
  },
  {
    name: 'invite',
    path: '/invite',
    component: () => import('./views/user/credits/invite.vue'),
    meta: {
      title: '邀请有礼',
      auth: true,
      report: true
    }
  },
  {
    name: 'share',
    path: '/share',
    component: () => import('./views/share/share.vue'),
    meta: {
      title: '分享'
    }
  },
  {
    name: 'wallet',
    path: '/wallet',
    component: () => import('./views/user/wallet/index.vue'),
    meta: {
      title: '钱包',
      auth: true
    }
  },
  {
    name: 'walletDetail',
    path: '/walletDetail',
    component: () => import('./views/user/wallet/detail.vue'),
    meta: {
      title: '明细'
    }
  },
  {
    name: 'payment',
    path: '/payment',
    component: () => import('./views/payment/home.vue'),
    meta: {
      title: '官网充值',
      showNav: true
    }
  },
  {
    name: 'paymentResult',
    path: '/paymentResult',
    component: () => import('./views/payment/result.vue'),
    meta: {
      title: '支付结果'
    }
  },
  {
    path: '/informationList',
    name: 'informationList',
    component: () => import('./views/user/infomationList/index.vue'),
    meta: {
      title: '个人信息收集清单',
      auth: true,
      login: true
    }
  },
  {
    path: '/informationDetail',
    name: 'informationDetail',
    component: () => import('./views/user/infomationList/detail.vue'),
    meta: {
      title: '',
      auth: true,
      login: true
    }
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('./views/user/vip/index.vue'),
    meta: {
      title: '我的等级',
      auth: true,
      login: true
    }
  },
  {
    path: '/activityCharge',
    name: 'activityCharge',
    component: () => import('./views/activity/chargePresentCoin.vue'),
    meta: {
      auth: true,
      title: '充值赠金币'
    }
  },
  {
    path: '/activityLottery',
    name: 'activityLottery',
    component: () => import('./views/activity/dayLottery.vue'),
    meta: {
      auth: true,
      title: '每日免费抽奖'
    }
  },
  {
    path: '/activityVipSaturday',
    name: 'activityVipSaturday',
    component: () => import('./views/activity/vipSaturday.vue'),
    meta: {
      auth: true,
      title: '周六会员日'
    }
  },
  {
    path: '/goodsDetail',
    name: 'goodsDetail',
    component: () => import('./views/user/credits/GoodsDetail.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/goodsConfirm',
    name: 'goodsConfirm',
    component: () => import('./views/user/credits/confirmOrder.vue'),
    meta: {
      title: '确认订单'
    }
  },
  {
    path: '/exchangeResult',
    name: 'exchangeResult',
    component: () => import('./views/user/credits/exchangResult.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/exchangeRecord',
    name: 'exchangeRecord',
    component: () => import('./views/user/credits/exchangeRecord.vue'),
    meta: {
      auth: true,
      title: '我的兑换'
    }
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('./views/user/credits/orderDetail.vue'),
    meta: {
      auth: true,
      title: '订单详情'
    }
  },
  {
    path: '/jumpWallet',
    name: 'jumpWallet',
    component: () => import('./views/activity/jumpWallet.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/activityDevilCity',
    name: 'activityDevilCity',
    component: () => import('./views/activity/devilCity.vue'),
    meta: {
      auth: true,
      title: '周五魔鬼城'
    }
  },
  {
    path: '/activityLevelChallenge',
    name: 'activityLevelChallenge',
    component: () => import('./views/activity/levelChallenge.vue'),
    meta: {
      auth: true,
      title: '闯关大挑战'
    }
  },
  {
    path: '/renew',
    name: 'renew',
    component: () => import('./views/user/renew/renew.vue'),
    meta: {
      title: '更新领奖励',
      auth: true,
      login: true
    }
  },
  {
    path: '/activityInvite',
    name: 'activityInvite',
    component: () => import('./views/activity/inviteActivity.vue'),
    meta: {
      auth: true,
      title: '邀请有礼',
      login: true
    }
  },
  {
    path: '/rankRule',
    name: 'rankRule',
    component: () => import('./views/rank/rankRule.vue'),
    meta: {
      auth: true,
      title: '排行榜规则与奖励',
      login: true
    }
  },
  {
    path: '/manage/machin',
    name: 'machin',
    component: () => import('./views/manage/machin.vue'),
    meta: {
      title: '机器管理'
    }
  },
  {
    path: '/taskcenter',
    name: 'taskcenter',
    component: () => import('./views/task/index.vue'),
    meta: {
      auth: true,
      title: '任务中心',
      login: true
    }
  },
  {
    path: '/octorberFirst',
    name: 'octorberFirst',
    component: () => import('./views/activity/octoberFirst.vue'),
    meta: {
      auth: true,
      title: '十一双重礼',
      login: true
    }
  },
  {
    path: '/',
    name: 'ad',
    component: () => import('./views/ad.vue'),
    meta: {
      auth: false,
      title: '线上电玩 玩街机',
      login: false
    }
  },
  {
    path: '/ad',
    name: 'ad',
    component: () => import('./views/ad.vue'),
    meta: {
      auth: false,
      title: '线上电玩 玩街机',
      login: false
    }
  }
]

const router = new VueRouter({
  routes
})
router.beforeEach(globalBeforeRouter)
router.afterEach(globalAfterRouter)
export default router
