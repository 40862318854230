







import { Component, Vue, Watch } from 'vue-property-decorator'
import { CommonModule } from '@/store/modules/common'
import { LoyoutModule } from '@/store/modules/layout'
@Component({
  name: 'App'
})
export default class extends Vue {
  get key() {
    return this.$route.path
  }

  get keepAlive() {
    return CommonModule.keepAlive
  }
  @Watch('$route')
  valueChange(to: any, from: any) {
    console.log('watch', this.$t(to.meta.title))
    LoyoutModule.setTitle(this.$t(to.meta.title))
  }
  mounted() {
    console.log('mounted token', CommonModule.token)
  }
}
