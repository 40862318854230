import { Vue } from '@/common/base'
import App from './App.vue'
import router from './router'
import store from '@/store'
import './styles/index.scss'
import VueI18n from 'vue-i18n'
import '../../common/utils/JSMath'
import { getAppLanguage } from '@/common/utils'
import { vantUyghur } from './vantUyghur'
import { Locale } from 'vant'
Vue.use(VueI18n)
//const lang = getQueryVariable('language') as string
const lang = getAppLanguage()
//const lang = 'uyghur'
const i18n = new VueI18n({
  locale: lang ? lang : 'zh_CN',
  //locale: 'uyghur',
  messages: {
    zh_CN: require('../../lang/zh.json'),
    uyghur: require('../../lang/uyghur.json')
  }
})
if (lang === 'uyghur') {
  Locale.add(vantUyghur)
}
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
